import React from 'react'
import Layout from '../../components/layout/layout'
import Header from '../../components/header/header'
import Nav from '../../components/nav/nav'
import Footer from '../../components/footer/footer'
import styles from './cv.module.css'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <div className={styles.cvContainer}>
        <p>
          Born in Buenos Aires, Argentina - 1960
          <br />
          Lives in Boston, Massachusetts
        </p>
        <section>
          <h2 className={styles.cvHeader}>Upcoming exhibitions: 2024 - 2025</h2>
          <p>– Lexington Art and Craft Society, Massachusetts - “Free Speech: Art & Activism” - July 21st.  To August 26th. 2024. </p>
          <p>– Proctor Academy Library Exhibit Space - Solo Exhibition - New Hampshire September/December - 2024</p>
          <p>– Fort Point Art Space, Boston, MA - “Earth Month: Environmental Awareness Project” -  Founder/Director and Curatorial - Community engagement/Full month of April 2025</p>
          <p>– Midway Gallery Boston, MA - “Thaw: We Are In Peril” - May - 2025</p>
          <p>– Universidad Católica Argentina - Buenos Aires (UCA) “Visiones de La Tierra” (Visions of The Earth) - Permanent Virtual exhibition on the Global Biodiversity Crisis. <a href="https://biblioteca.uca.edu.ar/expo/visiones/visiones.html" target="blank" rel="noopener noreferer">https://biblioteca.uca.edu.ar/expo/visiones/visiones.html</a></p>
        </section>
        <section>
          <h3 className={styles.cvHeader}>Solo & Two-Person Exhibitions:</h3>
          <p><span className={styles.bold}>2023/2024</span> – Gallery 1832 @ LabCentral - Cambridge, MA</p>
          <p>Two Women Show - Geomorphic Foresights: Meditations on The Environmental Crisis</p>
          <p><span className={styles.bold}>2022</span> – Spoke Gallery – Boston, MA</p>
          <p><span className={styles.bold}>2017</span> – Argentinian Embassy - Berlin, Germany</p>
          <p><span className={styles.bold}>2012</span> – Amazing Things Art Center – Framingham MA. USA</p>
          <p><span className={styles.bold}>2011</span> – Agencyport Technology – Boston USA</p>
          <p><span className={styles.bold}>2003</span> – Tarrant County Collage North East – Texas USA</p>
        </section>
        <section>
          <h3 className={styles.cvHeader}>Biennial/Triennial – Invitational:</h3>
          <p><span className={styles.bold}>2013</span> – Cluj International Ceramics Biennale – Cluj-Napoca Art Museum – Romania</p>
          <p><span className={styles.bold}>2013</span> – 1a Bienal Arte Sacro Contemporáneo – Museo Histórico Ramón Rivera Bermúdez, Puerto Rico</p>
          <p><span className={styles.bold}>2010</span> – 1st Biennial of the Americas – Museo de las Américas – Denver USA – "Libertadores"</p>
          <p><span className={styles.bold}>2004</span> – 3rd International Triennial of Ceramic Tile – Museo de la Cerámica Contemporánea, Dominican Republic</p>
        </section>
        <section>
          <h3 className={styles.cvHeader}>Selected group exhibitions:</h3>
          <h3 className={styles.cvHeader}>2024</h3>
          <p>– Lexington Arts & Crafts Society, Massachusetts - Reimagining Our World: Rejected Materials Reinvented</p>
          <h3 className={styles.cvHeader}>2023</h3>
          <p>– Piano Craft Gallery Boston, MA - "Inspiring Change for the Climate Crisis: The Non-Human is Pushing Back"</p>
          <p>– Midway Gallery Boston, MA - "State of the Earth" Project - 21 Women Artists on Global Biodiversity Crisis"</p>
          <p>– The Umbrella Art Center Gallery, Concord, MA – "Reflections: Listening to Nature"</p>
          <h3 className={styles.cvHeader}>2022</h3>
          <p>– Danforth Art Museum  Annual Juried Exhibition - Framingham University, MA. </p>
          <p>– Midway Gallery - Boston, MA</p>
          <p>– Lesley University - Earth Day - Boston, MA</p>
          <h3 className={styles.cvHeader}>2021</h3>
          <p>– WoArt Online Gallery, New York</p>
          <p>– The Umbrella Art Center - Concord, MA</p>
          <p>Change is in the Air</p>
          <h3 className={styles.cvHeader}>2020</h3>
          <p>– United Nations 75: Global Conversations 2020</p>
          <p>– Rockefeller Center, NYC - "The Flag Project"</p>
          <p>Installation of a flag design at the Rink</p>
          <p>– Mother Brook Art and Community Art Center - Dedham, MA</p>
          <p>Wearable Art and the WOW Factor</p>
          <h3 className={styles.cvHeader}>2018</h3>
          <p>– Berlin Science Week  - Art/Science "Eco-connectivity" – Humboldt-Universität zu Berlin - Thaer Institut</p>
          <p>– Nacht & Tag in den Gerichtshöfen - Art District Wedding, Berlin</p>
          <h3 className={styles.cvHeader}>2017</h3>
          <p>– François Schneider Foundation Centre d'Art Contemporain – Contemporary Talents Finalist</p>
          <p>– Transatlántica – Berlin – Ceramic sculptures and objects – Black and White series</p>
          <h3 className={styles.cvHeader}>2016</h3>
          <p>– Group Global 3000 – Berlin Art Week – “The Age of Man Kind, we create a new epoch of the Earth”</p>
          <p>– Caribbean Museum and Center for the Arts – Saint Croix, Virgin Islands</p>
          <p>"Women to Women, Island to Island: A Dialogue"</p>
          <h3 className={styles.cvHeader}>2015</h3>
          <p>– Templeton General Project – Collaboration with Supported Agriculture and ceramic –  Boston</p>
          <p>– Fort Point Arts Gallery – Boston USA</p>
          <p>– Spring Open Studios – Fort Point, Boston USA</p>
          <p>– Made in Fort Point Feature Artist – Boston USA</p>
          <p>– Midway Gallery – Boston USA</p>
          <h3 className={styles.cvHeader}>2014</h3>
          <p>– Fall Open Studios – Fort Point, Boston USA</p>
          <p>– Temporary Public Art, Founded by New England Foundation for the Arts  – Boston USA</p>
          <p>– Museo de Historia – Ponce, Puerto Rico</p>
          <p>– Spoke Gallery – Medicine Wheel, Boston USA</p>
          <p>– Spring Open Studios – Fort Point – Boston USA</p>
          <p>– Midway Gallery – Boston USA</p>
          <h3 className={styles.cvHeader}>2013</h3>
          <p>– Cluj International Ceramics Biennale – Cluj-Napoca Art Museum – Romania</p>
          <p>– Fort Point Open Studios – Boston USA</p>
          <p>– Fort Point Arts Community Gallery –  Fall Open Studios  – Boston USA</p>
          <p>– 1a Bienal Arte Sacro Contemporaneo – Museo Histórico Ramón Rivera Bermúdez, Puerto Rico</p>
          <p>– Midway Gallery – Boston USA</p>
          <h3 className={styles.cvHeader}>2012</h3>
          <p>– Traveling Exhibition Puerto Rico (Fine Art Popular Center – Compañia de Turismo Gallery</p>
          <p>–Casa Gonzalez Cuyar, Colegio de Arquitectos – Puerto Rico</p>
          <p>–Galería de la Comisión Estatal de Elecciones  de Puerto Rico – International Women's Month</p>
          <p>– Art at 12 Gallery – Boston USA</p>
          <p>– Midway Gallery – Boston USA</p>
          <p>– Compañía de Turismo – Puerto Rico</p>
          <h3 className={styles.cvHeader}>2011</h3>
          <p>– TransCultural Exchange International Conference, "An Abstract Poem of Freedom" Part II”</p>
          <p>Global Collaboration – Midway Gallery – Boston</p>
          <p>– Galería de la Comisión Estatal de Elecciones de Puerto Rico</p>
          <p>– New York Public Library Aguilar Branch – "The Last Book" – Luis Camnitzer Collaborative Project</p>
          <p>– Midway Artists Open Studios – Boston USA</p>
          <h3 className={styles.cvHeader}>2010</h3>
          <p>– 1st Biennial of the Americas – Museo de las Américas – Denver USA</p>
          <p>"Libertadores" – Ceramic & Mixed Media Installation</p>
          <p>– Centro Cultural Musical Criollo J.I. Qintón – Puerto Rico</p>
          <p>– Art at 12 Gallery – Boston USA</p>
          <p>– Fort Point Arts Community Open Studios – Boston USA</p>
          <h3 className={styles.cvHeader}>2009</h3>
          <p>– FPAC Gallery and Open Studios – Boston USA</p>
          <p>– Cultural Office of the City of Manatí – Puerto Rico</p>
          <p>– El-Status Platform of Puerto Rico Contemporary Art – Muñoz Rivera Park – Puerto Rico</p>
          <p>– Universidad Metropolitana de Cupey – Puerto Rico</p>
          <h3 className={styles.cvHeader}>2005-2008</h3>
          <p>– Código 3 Gallery – Puerto Rico</p>
          <p>– National Library of Buenos Aires, Argentina – "The Last Book" – Luis Camnitzer Collaborative Project</p>
          <p>– National Library New York – NYC –  "The Last Book" – Luis Camnitzer Collaborative Project</p>
          <p>– Universidad Interamericana de Fajardo – Puerto Rico</p>
          <p>– Ku Art Center – Beijing, China – Collaborative Ceramic Mural  Installations Olympic Games</p>
          <p>– Historical & Cultural Center of Cibuco, Puerto Rico</p>
          <p>– Uri Art Gallery – Puerto Rico</p>
          <h3 className={styles.cvHeader}>1998-2004</h3>
          <p>– 3rd International Triennial of Ceramic Tile – Museo de la Cerámica Contemporanea, Dominican Republic</p>
          <p>– Sanz The Shop Gallery – Puerto Rico</p>
          <p>– Tarrant County College – Texas, USA</p>
          <p>– Fort Worth Art Center – Fort Worth, Texas USA</p>
          <p>– May Fest Art Show – Fort Worth, Texas USA</p>
          <p>– Tarrant County College North East – Texas USA</p>
          <p>– The Art Gallery Broward College – Florida USA</p>
        </section>
        <section>
          <h3 className={styles.cvHeader}>Awards and Honors</h3>
          <p>– Massachusetts Cultural Council Grant recipient 2023</p>
          <p>– Selected for the Danforth Art Museum Annual Juried Exhibition</p>
          <p>– Artepreneur Prize - NYC - 2020</p>
          <p>– Fondation François Schneider - Centre d'art contemporain -Finalist Contemporary Talents 2017</p>
          <p>– Grant – New England Foundation for the Arts– Massachusetts USA – 2014</p>
          <p>– Featured Artist Saatchi Gallery – Curator Rebecca Wilson – London – 2013</p>
          <p>– Selected Cluj-Napoca Art Museum – International Contemporary Ceramic Biennale – Romania – 2013</p>
          <p>– Featured Artist Ceramic Now Magazine – Romania – Curator Vasi Hirdo</p>
          <p>– Invitational Solo Exhibition, Amazing Things Art Center – Massachusetts USA – 2012</p>
          <p>– Invitational – Museo de las Americas – 1st Biennial of the Americas – Denver USA – 2010</p>
          <p>– American College Recognition of Outstanding Merit and Accomplishment – Texas – 2003</p>
          <p>– Excellence in Education – Distinguished Scholarship – Tarrant County College, Texas –2003</p>
          <p>– Friend of Education Award – Public Art Mosaic Mural – Texas USA –2003</p>
        </section>
        <section>
          <h3 className={styles.cvHeader}>Education:</h3>
          <p>Tarrant County College NE – Fine Arts – Texas</p>
        </section>
        <section>
          <h3 className={styles.cvHeader}>Private and Public Collections:</h3>
          <p>USA – Puerto Rico – Argentina – Spain – Poland – Australia – Museum of Contemporary Ceramic, Dominican Republic – Wilshire Elementary School – Euless Texas – Academia Perpetuo Socorro, San Juan, Puerto Rico</p>
          <p>Collaborative Public Art Project – Puerto Rico Institute of Culture: Expres-Arte a Todo Rincón: City of Carolina, Toa Alta, Toa Baja, Aguas Buenas and Rio Grande, Puerto Rico – Ceramic Murals</p>
          <p>Ku Art Center – Beijing China – Public Installation for the 2008 Olympic Games – Collaboration</p>
        </section>
        <section>
          <h3 className={styles.cvHeader}>Artist-in-Residence</h3>
          <p>Wilshire Elementary School – Public Mosaic Mural – Euless, Texas (2001-2003)</p>
          <p>Academia del Perpetuo Socorro – Public Mosaic Mural – Puerto Rico (2005-2009)</p>
          <p>Puerto Rico Institute of Culture, Expres-Arte Program – Community Public Art (2005-2009)</p>
        </section>
      </div>
    </main>
    <Footer />
  </Layout>
)
